.logo-header-item-daisy-icon {
  animation: rotation 2s linear infinite;
  animation-play-state: paused;
}

.logo-header-item-container:hover .logo-header-item-daisy-icon {
  animation-play-state: running;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(365deg);
  }
}
